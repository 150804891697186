<template>
  <span class="hero-layer-wrapper xyz-in" xyz="left fade duration-10 delay-2" style="--xyz-translate-x: -5%">
    <svg class="hero-layer"  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 738 673" style="enable-background:new 0 0 738 673;" xml:space="preserve">
    <path d="M608.3,50.4c56.8,36.2,100.4,99.4,108.7,166c8.3,66.1-18.6,136.1-54.3,202.7c-36.2,67.1-81.3,130.7-151.3,180.7
      c-70.5,49.5-166,84.7-235,55.8S165.2,533.2,113.8,445c-50.9-88.6-111.1-171.9-96-238.9C33.5,139,124.1,88.1,202.4,55.8
      s144.4-46.5,212-49C482,4.9,552,14.2,608.3,50.4z"/>
    </svg>
  </span>
</template>

<script lang=ts>
export default {
  name: 'HeroLayer'
}
</script>
