import { loadStripe, Stripe, StripeElementLocale } from '@stripe/stripe-js'
import { config } from '@/conf'
import { XHR } from '@/utils/xhr'
import { localstore } from '@/utils/localstorage'

class Checkout {
  stripe!: Stripe
  spk!: string
  trial_pricing!: {[key: string]: number}
  sub_pricing!: {[key: string]: number}
  trial_price!: () => string
  subscription_price!: () => string
  on_change?: () => void

  constructor () {
    const xhr = new XHR()
    // set pre-defined pricing (for dev)
    this.trial_pricing = {}
    this.sub_pricing  = {}

    // fetch stripe public key and pricing from server
    xhr.get('/stripe').then(r => {
      const resp_data = r.decode<StripeInfo>()
      if (!resp_data) { console.error('failed to get stripe info'); return }
      // set pub key
      this.spk = resp_data!.spk
      // set updated pricing
      this.trial_pricing = resp_data!.trial_pricing
      this.sub_pricing = resp_data!.sub_pricing

      // set default currency when there is only one currency configured.
      // the default currency for multi currency apps are defined
      // in the url and has been set before this point
      const currencies = Object.keys(this.trial_pricing)
      if (currencies.length === 1) {
        localstore.set('currency', currencies[0])
      }

      // load pricing for currency (stored in local storage)
      this.trial_price = trial_price_multi_cur
      this.subscription_price = subscription_price_multi_cur


      // trigger event listener
      if (this.on_change) { this.on_change() }

    }).catch(e => {
      console.error("failed to get stripe public key")
    })
  }

  // initializes stripe.js module
  init () {
    // @ts-ignore
    loadStripe(this.spk).then((stripe: Stripe) => {
      this.stripe = stripe
    })
  }

  // load_pricing () {
  //   this.trial_price()
  //   this.subscription_price()
  // }

  // launch checkout process
  launch (props: CheckoutConfig) {
    // init stripe elements
    const elements = this.stripe.elements({
      clientSecret: props.token,
      locale: (config.lang.iso === 'latam' ? 'es-419' : config.lang.iso) as StripeElementLocale
    })

    // create and mount stripe element
    const payment_el = elements.create('payment', { terms: {card: 'never'} })
    payment_el.mount('#payment-element')

    // on payment element 'ready' event. element is rendered and mounted
    payment_el.on('ready', (event) => {
      // fire on_ready handler
      props.on_ready()

      // submit event listener
      const form = document.getElementById('payment-form')!
      form.addEventListener('submit', async (event) => {
        event.preventDefault()

        // fire on_busy handler
        props.on_busy(true)

        // confirm setup intent
        const { error } = await this.stripe.confirmPayment({
          elements, confirmParams: {
            return_url: checkout_complete_url(props.uid),
            // billing_details: {
            //  name: nameInput.value,
            // },
          }
        })

        // immediate error (invalid card data ect.)
        if (error) {
          // ******-----
          console.log(error)
          // ------*****

          // card declined
          if (error.type == 'card_error') {
            // get card check num and increment
            // let n = check_card_n(); n ++
            // localstore.set('XNpD5J8X', n+'NpD5J8X')
          }
          // fire on_error handler
          props.on_error(error.message!)
          // fire on_busy handler
          props.on_busy(false)
        } else {
          // customer will be redirected to an intermediate site to authorize the payment,
          // then redirected to the `return_url`

          // fire on_busy handler
          props.on_busy(false)
        }
      })
    })

    // fire on_change event when user focus on element (to hide any previous errors)
    payment_el.on('focus', (event) => {
      props.on_change()
    })

    return true
  }
}


// checkout configuration
interface CheckoutConfig {
  uid       : string
  token     : string
  on_ready  (): void
  on_busy   (_:boolean): void
  on_error  (_:string): void
  on_change (): void
}

// returns the url where the user is directed after completing checkout
const checkout_complete_url = (uid: string) : string => {
  // dev mode
  if (process.env.NODE_ENV === 'development') {
    console.log("checkout configured in dev mode [endpoint: http://127.0.0.1:4444]")
    return "http://127.0.0.1:4444/checkout?uid="+uid+"&lang="+config.lang.iso
  }

  // append user language iso2 code if the language is set in url (forced)
  if (config.lang.in_url) {
    // url with app domain name defined in config, user id and user language
    return "https://"+config.app+"/checkout?uid="+uid+"&lang="+config.lang.iso
  }

  // url with app domain name defined in config
  return "https://"+config.app+"/checkout?uid="+uid
}

// global checkout class
let checkout: Checkout
const InitCheckout = () => {
  checkout = new Checkout()
}

const currency_formats: {[key: string]: string} = {
  aed: "{n} د.إ",
  afn: "Af {n}",
  all: "ALL {n}",
  amd: "AMD {n}",
  ars: "AR$ {n}",
  aud: "AU$ {n}",
  azn: "₼{n}",
  bam: "KM {n}",
  bbd: "Bds$ {n}",
  bdt: "Tk {n}",
  bgn: "BGN {n}",
  bhd: "BD {n}",
  bif: "FBu {n}",
  bnd: "BN$ {n}",
  bob: "Bs {n}",
  brl: "R$ {n}",
  bsd: "B$ {n}",
  bwp: "P{n}",
  byn: "Br {n}",
  bzd: "BZ$ {n}",
  cad: "${n}",
  cdf: "CDF {n}",
  chf: "{n} Fr",
  clp: "CL$ {n}",
  cny: "CN¥ {n}",
  cop: "CO$ {n}",
  crc: "₡ {n}",
  cve: "CV$ {n}",
  czk: "{n} Kč",
  djf: "Fdj {n}",
  dkk: "{n} kr",
  dop: "RD$ {n}",
  dzd: "DA {n}",
  eek: "Ekr {n}",
  egp: "EGP {n}",
  ern: "Nfk {n}",
  etb: "Br {n}",
  eur: "€ {n}",
  eur2: "€ {n}",
  eur3: "€ {n}",
  gbp: "£ {n}",
  gel: "GEL {n}",
  ghs: "GH₵ {n}",
  gnf: "FG {n}",
  gtq: "Q{n}",
  hkd: "HK$ {n}",
  hnl: "HNL {n}",
  hrk: "kn {n}",
  huf: "{n} Ft",
  idr: "Rp {n}",
  ils: "₪ {n}",
  inr: "₹ {n}",
  iqd: "IQD {n}",
  irr: "IRR {n}",
  isk: "Ikr {n}",
  jmd: "J$ {n}",
  jod: "JD {n}",
  jpy: "¥ {n}",
  kes: "Ksh {n}",
  khr: "KHR {n}",
  kmf: "CF {n}",
  krw: "₩ {n}",
  kwd: "KD {n}",
  kzt: "KZT {n}",
  lbp: "L.L. {n}",
  lkr: "SLRs {n}",
  ltl: "Lt {n}",
  lvl: "Ls {n}",
  lyd: "LD {n}",
  mad: "MAD {n}",
  mdl: "MDL {n}",
  mga: "MGA {n}",
  mkd: "MKD {n}",
  mmk: "MMK {n}",
  mop: "MOP$ {n}",
  mur: "MURs {n}",
  mxn: "MX$ {n}",
  mxn2: "MX$ {n}",
  myr: "RM {n}",
  mzn: "MTn {n}",
  nad: "N$ {n}",
  ngn: "₦ {n}",
  nio: "C$ {n}",
  nok: "{n} kr",
  npr: "NPRs {n}",
  nzd: "NZ$ {n}",
  omr: "OMR {n}",
  pab: "B/. {n}",
  pen: "S/. {n}",
  php: "₱ {n}",
  pkr: "PKRs {n}",
  pln: "zł{n}",
  pyg: "₲ {n}",
  qar: "QR {n}",
  ron: "{n} lei",
  rsd: "din. {n}",
  rub: "RUB {n}",
  rwf: "RWF {n}",
  sar: "{n} ر.س",
  sdg: "SDG {n}",
  sek: "{n} kr",
  sgd: "S$ {n}",
  sos: "Ssh {n}",
  syp: "SY£ {n}",
  thb: "฿ {n}",
  tnd: "DT {n}",
  top: "T$ {n}",
  try: "TL {n}",
  ttd: "TT$ {n}",
  twd: "NT$ {n}",
  tzs: "TSh {n}",
  uah: "₴ {n}",
  ugx: "USh {n}",
  usd: "$ {n}",
  usd2: "$ {n}",
  usd3: "$ {n}",
  uyu: "$U {n}",
  uzs: "UZS {n}",
  vef: "Bs.F. {n}",
  vnd: "₫ {n}",
  xaf: "FCFA {n}",
  xcd: "EC$ {n}",
  xof: "CFA {n}",
  yer: "YR {n}",
  zar: "R {n}",
  zmk: "ZK {n}",
  zwl: "ZWL$"
}

const no_cent = ['jpy', 'clp', 'krw']

const trial_price_multi_cur = () => {
  // get currency from session storage
  const currency = localstore.get('currency') ?? 'eur'
  // get amount int
  let amount = checkout.trial_pricing[currency]
  if (!no_cent.includes(currency)) {
    amount = amount/100
  }
  // return formatted price
  return currency_formats[currency].replace('{n}', amount.toFixed(2))
}
const subscription_price_multi_cur = () => {
  // get currency from session storage
  const currency = localstore.get('currency') ?? 'eur'
  console.log(subscription_price_multi_cur, currency, no_cent, no_cent.includes(currency))
  // get amount int
  let amount = checkout.sub_pricing[currency]
  if (!no_cent.includes(currency)) {
    amount = amount/100
  }
  // return formatted price
  return currency_formats[currency].replace('{n}', amount.toFixed(2))
}

// stripe pub key and pricing
interface StripeInfo {
  spk           : string
  trial_pricing : {[key: string]: number}
  sub_pricing   : {[key: string]: number}
}

export { checkout, CheckoutConfig, InitCheckout, currency_formats }
