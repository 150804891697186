<template>
  <div class="mockup xyz-in" xyz="fade">
    <img src="@/assets/hero-layer.png" class="hero-bg-layer"/>

    <img src="@/assets/mockup-1.png" alt="Locate any phone, anywhere" class="xyz-in phone-mockup" xyz="up fade duration-6 delay-3" style="--xyz-translate-y: 10%"/>
    <div class="alert xyz-nested text-center" xyz="fade down small-5 delay-9 duration-4 ease-out-back">
      <h6 class="text-primary mb-05 ">
        <!--<img src="@/assets/icon.svg" alt="Geosite">-->
        {{ strings.home.main[18] }}
      </h6>
      <p class="text-dark">{{ strings.home.main[20] }}</p>
    </div>

    <span class="pin xyz-in" xyz="fade delay-10"><span></span></span>

    <div class="button bg-primary btn xyz-in" xyz="fade delay-10">{{ strings.core['Locate'] }}</div>
  </div>
</template>

<script lang=ts>
import { strings } from '@/strings'

export default {
  name: 'HeroImage',
  setup () {
    return { strings }
  }
}
</script>
