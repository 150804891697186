<template>
  <form class="tracker-input" @submit.prevent="on_submit">
    <!-- dail code select -->
    <dailcode-select ref="dailcode-select" @change="on_dialcode_change" :value="dial"/>

    <!-- number input field -->
    <input v-model="phone_input" type="tel" :placeholder="placeholder || strings.core['Phone']" autofocus @focus="hide_error()" required />

    <!-- submit button -->
    <button v-if="!hide_btn" :class="btn_color || 'bg-primary'" class="btn" type="submit" >
      <span>{{ btn_text }}</span>
      <v-icon icon="next"/>
    </button>

    <!-- error -->
    <div v-if="phone_error !== ''" class="error bg-red">
      {{ strings.core["Number"] }} {{ phone_error }} {{ strings.core["NotValid"] }}
      <a @click="hide_error()">
        <v-icon icon="close"/>
      </a>
    </div>
  </form>
</template>

<script lang=ts>
import { ref } from 'vue'
import { strings} from '@/strings'
import { validate_phone } from '@/utils/validators'
import { Country } from '@/utils/countries'
import { parse_text_input } from '@/utils/forms'

import DailcodeSelect from '@/components/ui/dailcode-select.vue'
import VIcon from '@/components/icons/icon.vue'

export default {
  name: 'PhoneInput',
  components: { VIcon, DailcodeSelect },
  props: {
    btn_text: null,
    btn_color: null,
    placeholder: null,
    dial: null,
    number: null,
    hide_btn: false
  },
  setup (props: any, { emit }: any) {
    // phone number input value
    const phone_input = ref(props.number || "")
    // phone number used in error message
    const phone_error = ref("")

    // selected country
    let country: Country

    // on search form submit event
    const on_submit = () => {
      // parse input value
      const phone_number = parse_text_input(phone_input.value)
      // undefined
      if (!phone_number) { return }

      // get dail code
      const dialcode = country.dial_code

      // validate phone number value from input
      const parsed_number = validate_phone(dialcode, phone_number)

      // is invalid
      if (parsed_number == undefined) {
        phone_error.value = `${dialcode} ${phone_number}`
        return
      }
      // emit ready event
      emit('ready', parsed_number)
    }

    // on dialcode change event
    const on_dialcode_change = (c: Country) => {
      country = c
    }

    // hides error
    const hide_error = () => {
      phone_error.value = ""
    }

    return { phone_input, on_submit, strings, phone_error, hide_error, on_dialcode_change }
  }
}
</script>
