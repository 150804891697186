<script setup lang="ts">
import { get_geo, user_geo } from '@/utils/geo'
import { countries, Country } from '@/utils/countries'
import { ref } from 'vue'
import { strings } from '../../strings'

const country = ref<Country|undefined>()

if (user_geo) {
  country.value = countries[user_geo]
} else {
  const iso2 = get_geo() ?? 'ES'
  country.value = countries[iso2]
}

</script>

<!-- template markdown -->
<template>
  <div class="mockup-modal ">



    <div>
      <label class="mb-1">
        <span class="dot"></span>
        <span class="text-dark">{{ strings.core['PhoneError'] }}</span>
      </label>

      <div class="input locating-input bg-white">
        <div class="flag">
          <img :src="`/img/flags/${country!.code.toLowerCase()}.svg`" />
          <span>{{ country!.dial_code }}</span>
        </div>
        <div class="pl-075">
          10 101 0101
        </div>
      </div>
    </div>

    <div class="mt-1">
      <label class="mb-1">
        <span class="dot"></span>
        <span class="text-dark">{{ strings.core.MessageLb }}</span>
      </label>

      <div class="input bg-white p-075" style="border-radius: .5rem">
        {{ strings.core.TextMsgDefault }}
      </div>
    </div>

    <div class="mt-2">
      <div class="button btn block bg-primary btn" >{{ strings.core['Start'] }}</div>
    </div>

  </div>
</template>
