<script setup lang="ts">
import { strings } from '@/strings'
import Icon from '@/components/icons/icon.vue'
import { ref } from 'vue'

// faq tab active item index
const active = ref(0)
//  faq item on select
const on_select = (i: number) => {
  active.value = i
}

const show_more = ref(false)
const on_show_more= () => {
  show_more.value = !show_more.value
}

const faq_data = [
  {
    heading: strings.faq[2],
    text: strings.faq[3]
  },
  {
    heading: strings.faq[4],
    text: strings.faq[5]
  },
  {
    heading: strings.faq[6],
    text: strings.faq[7]
  },
  {
    heading: strings.faq[8],
    text: strings.faq[9]
  },
  {
    heading: strings.faq[10],
    text: strings.faq[11]
  },
  {
    heading: strings.faq[12],
    text: strings.faq[13]
  },
  {
    heading: strings.faq[14],
    text: strings.faq[15]
  },
  {
    heading: strings.faq[16],
    text: strings.faq[17],
    link: {
      path: '/unsubscribe',
      text: strings.core["Unsubscribe"]
    }
  },
  {
    heading: strings.faq[18],
    text: strings.faq[19],
    link: {
      path: '/reset-password',
      text: strings.core["ResetPassword"]
    }
  }
]

</script>

<!-- template markdown -->
<template>
  <div id="faq" class="home-faq section">
    <div class="container">

      <div>
        <div class="divider bg-primary section-divider mb-2"> </div>
        <h3 class="heading text-center">{{ strings.faq[1] }}</h3>
      </div>

      <div v-if="show_more === true" class="faq-list mt-4" :key="show_more">

        <div v-for="(item, i) of faq_data" @click="on_select(i)" class="item card bg-white shadow-dark" :class="{'active': active === i}">
          <h6 class="heading">
            <span >{{ item.heading }}</span>
          </h6>
          <div class="answer xyz-in" xyz="fade duration-8">
            <p>{{ item.text }}</p>
            <router-link v-if="item.link" class="btn bg-light mt-1" :to="item.link.path">{{ item.link.text }}</router-link>
          </div>
        </div>

        <div class="show-more text-center text-dark">
          <a @click="on_show_more">
            Show Less
          </a>
        </div>
      </div>

      <div v-else class="faq-list mt-4" :key="show_more">
        <div v-for="(n, i) in 5" :key="i" @click="on_select(i)" class="item card bg-white shadow-dark" :class="{'active': active === i}">
          <h6 class="heading">
            <span >{{ faq_data[i].heading }}</span>
          </h6>
          <div class="answer xyz-in" xyz="fade duration-8">
            <p>{{ faq_data[i].text }}</p>
            <router-link v-if="faq_data[i].link" class="btn bg-light mt-1" :to="faq_data[i].link!.path">{{ faq_data[i].link!.text }}</router-link>
          </div>
        </div>

        <div class="show-more text-center text-dark with-bg">
          <a @click="on_show_more">
            Show more
          </a>
        </div>
      </div>

    </div>

  </div>
</template>
