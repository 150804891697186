 <template>
  <img :src="`/img/flags/${img}.svg`" :alt="img" loading="lazy" />
</template>

<script lang=ts>
export default {
  name: 'DialcodeFlag',
  props: {
    country: undefined
  },
  data () {
    return {
      img: undefined
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.img = this.country.toLowerCase()
    })
  }
}
</script>
