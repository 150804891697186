<template>
  <div v-if="active" class="modal" :class="{'active': visible}">
    <div>
      <a v-if="!modal.props['required']" class="close" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M9.42,7.96L15.24,2.14c.35-.35,.35-.92,0-1.27s-.92-.35-1.27,0l-5.82,5.82L2.33,.87c-.35-.35-.92-.35-1.27,0s-.35,.92,0,1.27L6.88,7.96,1.06,13.79c-.35,.35-.35,.92,0,1.27,.18,.18,.4,.26,.63,.26s.46-.09,.63-.26l5.82-5.82,5.82,5.82c.18,.18,.4,.26,.63,.26s.46-.09,.63-.26c.35-.35,.35-.92,0-1.27l-5.82-5.82Z"/></svg>
      </a>
      <component :is="component()"></component>
    </div>
  </div>
</template>

<script lang=ts>
import { modal } from '@/components/main/modal'
import { ref } from 'vue'

export default {
  name: 'Modal',
  setup () {
    const active = ref(false)
    const visible = ref(false)
    const component = () => { return modal.component }

    // set modal state handlers
    modal.on_activate = () => {
      active.value = true
      setTimeout(() => { visible.value = true }, 50)
    }
    modal.on_deactivate = () => {
      visible.value = false
      setTimeout(() => { active.value = false }, 50)
    }

    // modal close func
    const close = () => {
      modal.close()
    }

    return {
      active, visible, component, close, modal
    }
  }
}
</script>
