<script setup lang="ts">

import { onMounted, ref } from 'vue'
import { countries, Country } from '@/utils/countries'
import { get_geo, user_geo } from '@/utils/geo'
import DialcodeFlag from '@/components/ui/disalcode-flag.vue'

// props
const props = defineProps<{
  value: undefined
}>()
// emits
const emit = defineEmits(['change'])

const selected = ref({
    name: 'Italy',
    dial_code: '+39',
    code: 'IT'
  })

const active = ref(false)
const mounted = ref(false)
const countries_list = ref(countries)

const on_select = (country: any) => {
  selected.value = country
  emit('change', selected.value)
}
const toggle = () => {
  active.value = !active.value
}
const hide = () => {
  if (active.value) active.value = false
}
const set = (iso: string) => {
  selected.value = countries[iso]
  emit('change', selected.value)
}


const search_arr = Object.values(countries)
const on_search = (evt: Event) => {
  // @ts-ignore
  const { value } = evt.target
  const key = value.trim().toLowerCase();
  if (!key || key === '') {
    countries_list.value = countries
    return
  }

  countries_list.value = {}
  for (let i = 0; i < search_arr.length; i++) {
    const obj = search_arr[i]
    if (obj.name.toLowerCase().includes(key)) {
      countries_list.value[obj.code] = obj
    }
  }
}

onMounted(() => {
  if (props.value) {
    selected.value = countries[props.value]
    emit('change', selected.value)
  } else {

    if (user_geo) {
      selected.value = countries[user_geo]
      emit('change', selected.value)
    } else {
      const iso2 = get_geo() ?? 'ES'
      selected.value = countries[iso2]
      emit('change', selected.value)
    }
  }

  setTimeout(() => {
    mounted.value = true
  }, 100)
})
</script>

<!-- template markdown -->
<template>
  <div class="dial-select" v-blur="hide">

    <!-- toggle -->
    <a class="toggle" @click="toggle()">
      <span v-if="!mounted" style="height: 15px; width: 20px; background-color: #d1d1d7; border-radius: 4px; margin-right: 0.75rem"></span>
      <img v-if="mounted" :src="`/img/flags/${selected.code.toLowerCase()}.svg`" :alt="selected.code" />
      <span>{{ selected.dial_code }}</span>
    </a>

    <!-- dropdown -->
    <div v-if="mounted" v-show="active" class="menu shadow-dark rounded">

      <!-- search -->
      <div class="search px-15 mb-1">
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-primary">
            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
          </svg>
          <span class="divider"></span>
        </span>
        <input type="text" name="search" placeholder="" class="input" @input="on_search" />
      </div>


      <a v-for="(c, i) in countries_list" :key="i" @click="on_select(c)">
        <dialcode-flag :country="i"/>
        <span>{{ c.dial_code }}</span>
        <span>{{ c.name }}</span>
      </a>

    </div>

  </div>
</template>

