import { init_language } from '@/utils/language'

const config = {
  app   : window.location.hostname,
  lang  : init_language('en'),
  email : '',
  set_email (src: string): string {
    return src.replaceAll('info@geosite.mobi', this.email)
  }
}

config.email = 'info@geositehelp.com'

export { config }

