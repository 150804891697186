<template>
  <div class="col-md-6">

    <div class="text-block">
      <h1 class="xyz-in" xyz="fade down-2 duration-8 delay-1">{{ strings.core["Slogan"] }}</h1>

      <p class="lead mt-3 xyz-in" xyz="fade down duration-8 delay-3">{{ strings.core['PhoneError'] }}</p>
    </div>

    <!-- Tracker Input Field -->
    <phone-input btn_color="bg-primary" @ready="submit" ref="phone-input" :btn_text="strings.core['Start']" class="mt-1 xyz-in" xyz="fade duration-8 delay-3"/>

    <p class="lead mt-2 xyz-in" xyz="fade duration-8 delay-3">{{ strings.core['Description'] }}</p>

  </div>
</template>

<script lang=ts>
import { strings } from '@/strings'
import { locator, ParsedPhoneNumber } from '@/utils/locator'
import { router } from '@/router'
import PhoneInput from '@/components/ui/phone-input.vue'

export default {
  name: 'HeroContent',
  components: { PhoneInput },
  data () {
    return {
      strings: strings
    }
  },
  methods: {
    submit (parsed_number: ParsedPhoneNumber) {
      // init locator and save parsed number
      locator.init(parsed_number)

      router.push(`/sign-up?c=${encodeURIComponent(parsed_number.dial)}&n=${parsed_number.number}`)
      // show sign-up modal and set the parsed number in properties
      // modal.show(SignUpModal, parsed_number)
    }
  },
  mounted () {

  }
}
</script>
