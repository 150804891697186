<template>
  <footer class="app-footer bg-white">
    <div class="container">

      <div class="">
        <!-- Logo -->
        <router-link to="/" class="footer-logo mb-4">
          <img src="./../../assets/logo.svg" alt="Geosite" />
        </router-link>
      </div>

      <!-- Top -->
      <div class="row">
        <div class="col-md-4">

          <!-- language -->
          <div class="mb-2">
           <label class="d-flex align-c mb-1">
              <svg class="text-primary" style="height: 18px; flex: 0 0 18px; fill: currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M16,8.02c0,1.58,0,3.16,0,4.74,0,1.57-1.02,2.84-2.54,3.17-.24,.05-.48,.07-.73,.07-3.16,0-6.31,0-9.47,0-1.68,0-2.98-1.11-3.24-2.77-.02-.16-.03-.32-.03-.49,0-3.16,0-6.32,0-9.49C0,1.67,1.02,.4,2.54,.07,2.78,.02,3.02,0,3.27,0,6.42,0,9.58,0,12.73,0c1.68,0,2.98,1.11,3.24,2.77,.03,.18,.03,.37,.03,.56,0,1.56,0,3.12,0,4.69Zm-10.08,1.14c1.15-1.84,1.78-3.83,2.01-5.96,.3,0,.59,0,.88,0,.38,0,.7-.26,.78-.61,.12-.52-.25-.98-.81-.99-.72,0-1.44,0-2.16,0-.07,0-.13,0-.2,0-.09-.49-.36-.76-.76-.79-.43-.02-.7,.21-.87,.8-.06,0-.13,0-.2,0-.7,0-1.4,0-2.1,0-.21,0-.41,.05-.58,.18-.26,.21-.36,.56-.26,.88,.11,.33,.41,.54,.77,.54,1.23,0,2.46,0,3.69,0,.07,0,.13,0,.21,0-.2,1.69-.68,3.26-1.54,4.75-.06-.09-.11-.15-.15-.21-.29-.46-.5-.96-.65-1.49-.13-.47-.55-.72-.99-.6-.43,.11-.67,.55-.55,1.02,.24,.95,.68,1.79,1.28,2.56,.04,.05,.07,.1,.11,.14-.57,.75-1.22,1.41-1.93,2.01-.37,.31-.41,.8-.11,1.15,.3,.35,.79,.39,1.15,.06,.51-.46,1-.94,1.48-1.43,.21-.21,.39-.45,.58-.67,.34,.21,.67,.44,1.01,.62,.51,.28,1.11-.05,1.17-.63,.03-.34-.12-.6-.41-.78-.29-.17-.57-.35-.86-.54Zm3.32,3.64c1.02,0,2.03,0,3.03,0,.04,0,.11,.08,.13,.14,.14,.54,.28,1.08,.42,1.61,.12,.47,.54,.73,.98,.62,.45-.12,.7-.53,.58-1-.45-1.77-.9-3.54-1.37-5.31-.27-1-1.18-1.67-2.21-1.66-1.03,0-1.92,.68-2.19,1.67-.14,.5-.26,1-.39,1.5-.33,1.27-.65,2.54-.98,3.81-.12,.45,.13,.87,.57,.99,.43,.11,.84-.13,.97-.57,.03-.11,.06-.23,.09-.34,.12-.48,.25-.97,.37-1.45Z"/><path d="M11.96,11.19h-2.31c.1-.39,.2-.76,.29-1.14,.07-.25,.12-.51,.2-.76,.08-.28,.33-.47,.62-.49,.28-.02,.59,.15,.67,.42,.19,.64,.35,1.3,.52,1.96Z"/></svg>
              <span class="ml-05">{{ strings.core["Language"] }}</span>
            </label>

            <lang-select class="btn bg-white"/>
          </div>

          <!-- payment methods -->
          <div>
            <label class="d-flex align-c mb-1">
              <svg class="text-primary" style="height: 18px; flex: 0 0 18px; fill: currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z" clip-rule="evenodd" />
              </svg>
              <span class="ml-05">Secure Payment</span>
            </label>
            <img src="@/assets/pay-methods.svg" style="width: 200px" xyz=""/>
          </div>


        </div>

        <!-- Navs -->
        <div class="nav">
          <div>
            <label class="d-flex align-c">
              <svg class="text-primary" style="height: 18px; flex: 0 0 18px; fill: currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" clip-rule="evenodd" />
              </svg>
              <span class="ml-05">{{ strings.core["Pages"] }}</span>
            </label>
            <router-link to="/#how-it-works" class="link">{{ strings.core["How It Works"] }}</router-link>
            <router-link to="/pricing" slot="nav" class="link mt-05">{{ strings.core["Pricing"] }}</router-link>
            <router-link to="/faq" class="link mt-05">{{ strings.core["F.A.Q."] }}</router-link>
            <router-link to="/contact" class="link mt-05">{{ strings.core["Contact"] }}</router-link>
          </div>
          <div>
            <label class="d-flex align-c">
              <svg class="text-primary" style="height: 18px; flex: 0 0 18px; fill: currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M3 6a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3V6ZM3 15.75a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-2.25Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3v-2.25Z" clip-rule="evenodd" />
              </svg>
              <span class="ml-05">{{ strings.core["Account"] }}</span>
            </label>
            <router-link to="/dash" class="link">{{ strings.core["Dashboard"] }}</router-link>
            <router-link to="/login" class="link mt-05">{{ strings.core["Login"] }}</router-link>
            <router-link to="/sign-up" class="link mt-05">{{ strings.core["Register"] }}</router-link>
            <router-link to="/cc" class="link mt-05">{{ strings.core["Unsubscribe"] }}</router-link>
          </div>
          <div>
          </div>
        </div>

        <!--
        <div class="col-lg-3">
          <label>&nbsp;</label>

          <router-link to="/cookie-policy" class="link">{{ strings.core["Cookies Policy"] }}</router-link>
          <router-link to="/privacy-policy" class="link">{{ strings.core["Privacy Policy"] }}</router-link>
          <router-link to="/terms" class="link">{{ strings.core["Terms and Conditions"] }}</router-link>


          <label>We accept all cards</label>
          <div style="display: flex;">
            <icon icon="visa" style="flex: 0 0 40px; height: 40px"/>
            <icon icon="mastercard" style="flex: 0 0 40px; height: 40px" class="ml-05"/>
            <icon icon="maestro" style="flex: 0 0 40px; height: 40px" class="ml-05"/>
            <icon icon="amex" style="flex: 0 0 40px; height: 40px" class="ml-05"/>
            <icon icon="discover" style="flex: 0 0 40px; height: 40px" class="ml-05"/>
          </div>
<div style="display: flex;">
              <icon icon="visa" style="flex: 0 0 40px; height: 40px"/>
              <icon icon="mastercard" style="flex: 0 0 40px; height: 40px" class="ml-05"/>
              <icon icon="maestro" style="flex: 0 0 40px; height: 40px" class="ml-05"/>
              <icon icon="amex" style="flex: 0 0 40px; height: 40px" class="ml-05"/>
              <icon icon="discover" style="flex: 0 0 40px; height: 40px" class="ml-05"/>
            </div>

          <div class="mt-2">
            <label>Language</label>
            <div class="lang-select">
              <a class="btn bg-white" @click="">
                {{  }}
              </a>
            </div>
          </div>


        </div>    -->

      </div>

      <!-- Bottom -->
      <div class="row mt-4">
        <div class="col-md-6">
          <p>&copy; Geosite - {{ year }}</p>
        </div>
        <div class="col-md-6">
          <div class="footer-legal">
            <router-link to="/cookie-policy" class="link">{{ strings.core["Cookies Policy"] }}</router-link>
            <router-link to="/privacy-policy" class="link ml-1">{{ strings.core["Privacy Policy"] }}</router-link>
            <router-link to="/terms" class="link ml-1">{{ strings.core["Terms and Conditions"] }}</router-link>
          </div>
        </div>

      </div>
    </div>
  </footer>
</template>

<script>
import { languages } from '@/utils/language'
import { strings } from '@/strings'
import Icon from '@/components/icons/icon'
import LangSelect from '@/components/ui/lang-select'

export default {
  name: 'AppFooter',
  components: {LangSelect, Icon },
  setup () {
    const year = new Date().getFullYear()


    return {
      languages,
      strings,
      year
    }
  }
}
</script>
