<template>
  <div class="hero bg-white">

    <!-- background layer
    <hero-layer/> -->

     <div class="container">
      <div class="row">

        <!-- main content -->
        <hero-content/>

        <!-- image -->
        <div class="col-md-6 mockup">
          <hero-image/>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang=ts>
import HeroImage from '@/components/home/hero-image.vue'
import HeroLayer from '@/components/home/hero-layer.vue'
import HeroContent from '@/components/home/hero-content.vue'
export default {
  name: 'Hero',
  components: { HeroContent, HeroLayer, HeroImage }
}
</script>
