<template>
  <div class="v-home">
    <!-- hero -->
    <hero/>

    <!-- features -->
    <features/>

    <!-- showcase -->
    <showcase/>

    <!-- faq -->
    <home-faq/>

    <!-- child routes -->
    <router-view/>
  </div>
</template>

<script lang=ts>
import Hero from '@/components/home/hero.vue'
import Features from '@/components/home/features.vue'
import Showcase from '@/components/home/showcase.vue'
import { XHR } from '@/utils/xhr'
import { router } from '@/router'
import HomeFaq from '@/components/home/home-faq.vue'

export default {
  name: 'Home',
  components: { HomeFaq, Showcase, Features, Hero },
  mounted () {
    const xhr = new XHR()
    xhr.get('/login-verify').then(x => {
      if (x.data === '1') {
        router.push('/dash')
      }
    })
  }
}
</script>
